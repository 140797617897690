import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ListItem, NumberedList, Link } from '@entur/typography';
import '../../sales/guides.scss';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "transport-operator-guide"
    }}>{`Transport operator guide`}</h1>
    <p>{`This is a guide for how transport operators can make their products available for sale through the Entur Sales Platform.`}</p>
    <p>{`All transport operators who wish to make their products available for sale through our platform must support the TOMP API standard.`}</p>
    <NumberedList className="to-guides-toc" mdxType="NumberedList">
    <ListItem mdxType="ListItem">
        <Link href="#tomp-a-europeen-api-standard" mdxType="Link">
            TOMP: A Europeen API standard
        </Link>
        <NumberedList className="to-guides-toc" mdxType="NumberedList">
            <ListItem mdxType="ListItem">
                <Link href="#what-is-the-tomp-standard" mdxType="Link">What is the TOMP standard</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#why-did-we-choose-this-standard" mdxType="Link">Why did we choose this standard</Link>
            </ListItem>
            <ListItem mdxType="ListItem">
                <Link href="#why-should-you-support-this-standard" mdxType="Link">Why should you support this standard</Link>
            </ListItem>
        </NumberedList>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#our-reference-application" mdxType="Link">
            Our reference application
        </Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#realtime-data" mdxType="Link">
            Realtime data
        </Link>
    </ListItem>
    <ListItem mdxType="ListItem">
        <Link href="#implementation" mdxType="Link">
            Implementation
        </Link>
    </ListItem>
    </NumberedList>
    <h2 {...{
      "id": "tomp-a-europeen-api-standard"
    }}>{`TOMP: A Europeen API standard`}</h2>
    <p>{`TOMP = Transport Operator MaaS Provider.`}</p>
    <h3 {...{
      "id": "what-is-the-tomp-standard"
    }}>{`What is the TOMP standard`}</h3>
    <p>{`TOMP (Transport Operator to Mobility-as-a-Service Provider) is a European API standard designed to enable seamless integration
and communication between transport operators and Mobility-as-a-Service (MaaS) providers. It allows for functions like booking, payment,
and real-time data sharing, helping to create a unified, user-friendly mobility experience across Europe by connecting various transport services
within a single application.`}</p>
    <ul>
      <li parentName="ul">{`Read more about TOMP here: `}<a parentName="li" {...{
          "href": "https://github.com/TOMP-WG"
        }}>{`https://github.com/TOMP-WG`}</a></li>
      <li parentName="ul">{`The TOMP swagger doc: `}<a parentName="li" {...{
          "href": "https://app.swaggerhub.com/apis-docs/TOMP-API-WG/transport-operator_maas_provider_api/1.5.0#/"
        }}>{`https://app.swaggerhub.com/apis-docs/TOMP-API-WG/transport-operator_maas_provider_api/1.5.0#/`}</a></li>
    </ul>
    <h3 {...{
      "id": "why-did-we-choose-this-standard"
    }}>{`Why did we choose this standard`}</h3>
    <p>{`As a public transport company, we want to use standards to avoid 1-1 implementations between different services.
The TOMP API standard is a well-established standard with over 40 users spread over various countries in Europe and is a
standard based on `}<a parentName="p" {...{
        "href": "https://gbfs.org/"
      }}>{`GBFS`}</a>{` data. Since almost all data provided by shared mobility actors is based on GBFS data,
this also makes the implementation of the standard much easier as you do not need to convert between different data models.`}</p>
    <h3 {...{
      "id": "why-should-you-support-this-standard"
    }}>{`Why should you support this standard`}</h3>
    <p>{`WIP`}</p>
    <h2 {...{
      "id": "our-reference-application"
    }}>{`Our reference application`}</h2>
    <p>{`Team shared mobility at Entur have created a open source reference application with all needed controllers, data classes and
services with test data as a Kotlin Spring Boot application. We have also written a guide in the README with information about
what kind of endpoints you as a Transport Operator need to implement. Since the TOMP-standard supports all kinds of mobilities,
and we (as of 20th August 2024) only supports micro-mobility, the amount of endpoints and response data is at a minimum.`}</p>
    <ul>
      <li parentName="ul">{`Application: `}<a parentName="li" {...{
          "href": "https://github.com/entur/shared-mobility-to-ref"
        }}>{`https://github.com/entur/shared-mobility-to-ref`}</a></li>
      <li parentName="ul">{`Swagger doc: `}<a parentName="li" {...{
          "href": "https://petstore.swagger.io/?url=https://api.dev.entur.io/api-docs/shared-mobility-to-ref"
        }}>{`https://petstore.swagger.io/?url=https://api.dev.entur.io/api-docs/shared-mobility-to-ref`}</a></li>
    </ul>
    <h2 {...{
      "id": "realtime-data"
    }}>{`Realtime data`}</h2>
    <p>{`Since all shared mobility operators in Norway are required by law to deliver realtime data to Entur, our team and clients uses
the `}<a parentName="p" {...{
        "href": "https://developer.entur.org/pages-mobility-docs-mobility-v2"
      }}>{`Mobility API`}</a>{` to receive information about available assets,
system information, vehicle types, pricing plans, system regions and geofencing zones. Because of this a lot of TOMP-endpoints
becomes redundant, which also makes the implementation of the standard easier for transport operators.`}</p>
    <h2 {...{
      "id": "implementation"
    }}>{`Implementation`}</h2>
    <p>{`As mentioned, we have created an open source project who works as a reference app on how to implement the TOMP API standard.
Take a look at the README.md in the `}<a parentName="p" {...{
        "href": "https://github.com/entur/shared-mobility-to-ref/blob/main/README.md"
      }}>{`shared-mobility-to-ref`}</a>{`
application for how to implement all the required endpoints.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      